import React, { useCallback, useEffect, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import setFieldTouched from '../../util/setFiledTouched';
import { Button, FieldRadioButton, SavedCardDetails } from '../../components';

import css from './EditListingSubscriptionForm.css';
import SubscriptionPaymentForm from './SubscriptionPaymentForm';
import { deletePaymentMethod, savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { handleCardSetup } from '../../ducks/stripe.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { ensureCurrentUser, ensurePaymentMethodCard, ensureStripeCustomer } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  createStripeSetupIntent,
  loadData,
  stripeCustomer,
} from '../../ducks/subscriptionPanel.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import config from '../../config';

const { LatLng } = sdkTypes;

const HELP_LINK = process.env.REACT_APP_SUBSCRIPTION_PLAN_HELP;

const stripeElementsOptions = {
  fonts: [
    {
      family: 'sofiapro',
      fontSmoothing: 'antialiased',
      src:
        'local("sofiapro"), local("SofiaPro"), local("Sofia Pro"), url("https://assets-sharetribecom.sharetribe.com/webfonts/sofiapro/sofiapro-medium-webfont.woff2") format("woff2")',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const SUBSCRIPTION_PLAN = [
  {
    label: ['Just mates', 'Free'],
    value: 'just-mates',
    description: "Basic plan with comprehensive insurance, but you'll need to bring your own GPS."
  },
  {
    label: ['Good mates', '$10/month'],
    value: 'good-mates',
    description: "All benefits of Just mate plus GPS tracking for added peace of mind."
  },
  {
    label: ['Best mates', '$30/month'],
    value: 'best-mates',
    description: "Provides app lock/unlock for guests, priority search results, and is covered by our Drive mate Go guarantee program. Currently unavailable due to a tech upgrade. Expected availability: October 2024.",
    isComingSoon: true,
    isDisabled: true
  },
  {
    label: ['Fleet insurance (in-between bookings cover)', '$140/month'],
    value: 'best-mates-plus',
    description: "Comprehensive coverage for personal trips outside of Drive mate. Expected availability: End of September 2024.",
    isComingSoon: true,
    isDisabled: true
  },
];
const DEFAULT_PLAN_VALUE = SUBSCRIPTION_PLAN[0].value;

export const EditListingSubscriptionForm = props => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardState, setCardState] = useState(null);
  const [mountingCard, setMountingCard] = useState(null);
  const [stripeValue, setStripeValue] = useState(null);
  const [isStickySubmitVisible, setIsStickySubmitVisible] = useState(false);
  const [isError, setIsError] = useState('');

  const {
    currentUser,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    deletePaymentMethodInProgress,
    onCreateSetupIntent,
    onHandleCardSetup,
    onSavePaymentMethod,
    onDeletePaymentMethod,
    fetchStripeCustomer,
    scrollingDisabled,
    onManageDisableScrolling,
    intl,
    stripeCustomerFetched,
    fetchCurrentUser,
    initValues,
  } = props;

  const getClientSecret = setupIntent => {
    return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
  };

  const getPaymentParams = (currentUser, formValues) => {
    const { name, addressLine1, addressLine2, postal, state, city, country } = formValues;
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const paymentParams = {
      payment_method_data: {
        billing_details: billingDetails,
      },
    };

    return paymentParams;
  };

  const onStripeCall = async params => {
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const stripeCustomer = ensuredCurrentUser.stripeCustomer;
    const { stripe, card, formValues } = params;
    const container = document.getElementById('subscriptionForm-card');

    if (container) {
      await onCreateSetupIntent()
        .then(setupIntent => {
          const stripeParams = {
            stripe,
            card,
            setupIntentClientSecret: getClientSecret(setupIntent),
            paymentParams: getPaymentParams(currentUser, formValues),
          };
          mountingCard.mount(container);
          return onHandleCardSetup(stripeParams);
        })
        .then(result => {
          const newPaymentMethod = result.setupIntent.payment_method;
          // Note: stripe.handleCardSetup might return an error inside successful call (200), but those are rejected in thunk functions.
          return onSavePaymentMethod(stripeCustomer, newPaymentMethod);
        })
        .then(() => {
          // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod
          fetchStripeCustomer();
          fetchCurrentUser();
          setCardState('default');
          props.onSubmit(formValues);
        })
        .catch(error => {
          if (error.message) {
            setIsError(error.message);
          } else {
            setIsError(error.toString());
          }

          console.error(error);
        });
    } else {
      props.onSubmit(formValues);
    }
  };

  const handleRemovePaymentMethod = () => {
    onDeletePaymentMethod().then(() => {
      fetchStripeCustomer();
    });
  };

  useEffect(() => {
    props.initData();
  }, []);

  const handleScroll = useCallback(() => {
    let scrollTop = window.pageYOffset;
    // if (this.stickySubmitButtonRef) {
    //   const isVisible =
    //     parseInt(scrollTop + (window.innerHeight - 70)) >
    //     parseInt(this.stickySubmitButtonRef.current.offsetTop)
    //       ? true
    //       : false;
    //   this.setState({
    //     isStickySubmitVisible: !isVisible,
    //   });
    // }
  }, []);

  const handleSubmit = async values => {
    // const { onSubmit, inProgress, formId } = this.props;
    // const cardInputNeedsAttention = !this.state.cardValueValid;

    // if (inProgress || cardInputNeedsAttention) {
    //   // Already submitting or card value incomplete/invalid
    //   return;
    // }

    setIsSubmitting(true);
    setIsError(false);

    const params = {
      stripe: stripeValue,
      card: mountingCard,
      formId: 'subscriptionForm',
      formValues: values,
    };

    await onStripeCall(params);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for PaymentMethodsForm');
    }

    if (config.stripe.publishableKey) {
      const stripe = window.Stripe(config.stripe.publishableKey);

      const elements = stripe.elements(stripeElementsOptions);
      const cardToMount = elements.create('card', { style: cardStyles });
      setMountingCard(cardToMount);
      setStripeValue(stripe);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (window.innerWidth < 1024) {
          cardToMount.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
        } else {
          cardToMount.update({ style: { base: { fontSize: '20px', lineHeight: '32px' } } });
        }
      });
    }
    if (isStickySubmitVisible) {
      handleScroll();
    }
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;

  const hasDefaultPaymentMethod =
    currentUser &&
    ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id;

  // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
  const userName = currentUserLoaded
    ? `${ensuredCurrentUser.attributes.profile.firstName} ${ensuredCurrentUser.attributes.profile.lastName}`
    : null;

  const protectedData = ensuredCurrentUser.attributes.profile.protectedData || {};
  const { blockNo, building, floorUnit, city, country, postalCode } = protectedData;
  const { selectedPlace } = protectedData.location || {};
  const { address, origin } = selectedPlace || {};
  const initalValuesForStripePayment = {
    name: userName,
    blockNo,
    addressLine1: address,
    addressLine2: blockNo,
    location: {
      search: address,
      selectedPlace: {
        address,
        origin: new LatLng(origin && origin.lat, origin && origin.lng),
      },
    },
    building,
    floorUnit,
    city,
    country,
    postalCode,
    postal: postalCode,
  };

  const isDataFetched = initalValuesForStripePayment.name;
  const card = hasDefaultPaymentMethod
    ? ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).attributes.card
    : null;

  //console.log(card, 291111)

  const showForm = isDataFetched && (cardState === 'replaceCard' || !hasDefaultPaymentMethod);
  const showCardDetails = !!hasDefaultPaymentMethod;

  return (
    <FinalForm
      {...props}
      initialValues={{
        plan: initValues.plan || DEFAULT_PLAN_VALUE,
      }}
      mutators={{ setFieldTouched }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          // handleSubmit,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          form,
          submitButtonId,
        } = formRenderProps;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress || isSubmitting;
        const submitDisabled = invalid || disabled || submitInProgress;

        // console.log(showForm, stripeCustomerFetched, initalValuesForStripePayment, 204444);
        const isPaymentVisible = values.plan !== DEFAULT_PLAN_VALUE && stripeCustomerFetched;

        const subscriptionPlan = (
          <div className={css.subscriptionPlan}>
            <div className={css.customRadioOuter}>
              <div className={classNames(css.column, css.column12)}>
                <label className={css.subscriptionPlanTitle}>
                  {intl.formatMessage({
                    id: 'EditListingSubscriptionPanel.selectPlan.title',
                  })}
                </label>
              </div>
              <div className={classNames(css.column, css.column12)}>
                <div className={css.radioGroup}>
                  {SUBSCRIPTION_PLAN.map(plan => {
                    const { label, value, isComingSoon, description, isDisabled } = plan;
                    const [textValue, priceValue] = label;
                    const newLabel = (
                        <div className={css.recommendedContainer}>
                          
                          <div>
                              {textValue} - <span className={css.priceValue}>{priceValue}</span>
                          </div>
                          {isComingSoon && (
                            <span className={css.recommended}>
                              {intl.formatMessage({
                                id: 'EditListingSubscriptionPanel.comingsoon',
                              })}
                            </span>
                            
                          )}
                          {/* <div> {description} </div> */}
                        </div>
                    );
                    return (
                      <>
                        <FieldRadioButton
                          disabled={isDisabled ? true: false}
                          id={value}
                          name={'plan'}
                          label={newLabel}
                          value={value}
                          className={isComingSoon ? classNames(css.customRadio, css.comingSoon):css.customRadio }
                          showAsRequired={true}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );

        return (
          <>
            <div>{subscriptionPlan}</div>
            <div>
              {isPaymentVisible ? (
                <React.Fragment>
                  <div className={classNames(css.column, css.column12)}>
                    <label>
                      {intl.formatMessage({
                        id: 'EditListingSubscriptionPanel.addCardDetails.title',
                      })}
                    </label>
                    <div className={classNames(css.column, css.column12, css.cardPaymentInfo)}>
                      {intl.formatMessage({
                        id: 'EditListingSubscriptionPanel.cardPaymentInfo',
                      })}
                    </div>
                    {showCardDetails ? (
                      <SavedCardDetails
                        card={card}
                        currentUser={currentUser}
                        onManageDisableScrolling={onManageDisableScrolling}
                        onChange={setCardState}
                        onDeleteCard={handleRemovePaymentMethod}
                        deletePaymentMethodInProgress={deletePaymentMethodInProgress}
                        preventRemoveCard={true}
                      />
                    ) : null}
                    {showForm ? (
                      <SubscriptionPaymentForm
                        className={css.paymentForm}
                        formId="subscriptionForm"
                        initialValues={initalValuesForStripePayment}
                        form={form}
                        handleRemovePaymentMethod={handleRemovePaymentMethod}
                        hasDefaultPaymentMethod={hasDefaultPaymentMethod}
                        addPaymentMethodError={addPaymentMethodError}
                        deletePaymentMethodError={deletePaymentMethodError}
                        createStripeCustomerError={createStripeCustomerError}
                        handleCardSetupError={handleCardSetupError}
                        inProgress={isSubmitting}
                        isStickySubmitVisible={isStickySubmitVisible}
                        mountingCard={mountingCard}
                        card={card}
                      />
                    ) : null}
                  </div>
                </React.Fragment>
              ) : null}
            </div>

            {isError && (
              <div className={classNames(css.column, css.column12, css.error)}>{isError}</div>
            )}
            <div className={classNames(css.column, css.column12, css.infoSection)}>
              <div className={css.learnMore}>
                {intl.formatMessage({
                  id: 'EditListingSubscriptionPanel.learnMore',
                })}
                {' - '}
                <a href={HELP_LINK} target="_blank">
                  {intl.formatMessage({
                    id: 'EditListingSubscriptionPanel.learnMoreLink',
                  })}
                </a>
              </div>
              <div>
                {intl.formatMessage({
                  id: 'EditListingSubscriptionPanel.talkToHost',
                })}
              </div>
            </div>
              <div className={css.stickyButtons}>
                <div className={css.stickButtonsContainer}>
                  <div className={css.stickButtonsDescription}>
                    {/* <span className={css.descIcon}>
                      <img src={meterIcon} alt="" />
                      <img
                        src={meterNeedle}
                        alt=""
                        style={{ transform: meterDisplay }}
                        className={css.needle}
                      />
                    </span>
                    <p>
                      <strong>Good Demand:</strong> Based on your settings you are on your way to
                      attract good level of demand.
                    </p> */}
                  </div>
                  <div className={css.stickButton}>
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      ready={submitReady}
                      id={submitButtonId}
                      onClick={e => {
                        handleSubmit(values);
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>

            {/* <div className={css.stickyButtons}>
              <div className={css.stickButtonsContainer}>
                <div className={css.stickButtonsDescription}></div>
                <div className={css.stickButton}>

                </div>
              </div>
            </div> */}
          </>
        );
      }}
    />
  );
};

EditListingSubscriptionForm.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingSubscriptionForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  const {
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
  } = state.paymentMethods;

  const { stripeCustomerFetched } = state.subscriptionPanel;

  const { handleCardSetupError } = state.stripe;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    stripeCustomerFetched,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onHandleCardSetup: params => dispatch(handleCardSetup(params)),
  onCreateSetupIntent: params => dispatch(createStripeSetupIntent(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) =>
    dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod)),
  onDeletePaymentMethod: params => dispatch(deletePaymentMethod(params)),
  initData: () => dispatch(loadData()),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
});

const EditListingSubscriptionFormModified = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(EditListingSubscriptionForm);

export default EditListingSubscriptionFormModified;
